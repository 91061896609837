.SeoNews-module_seo-news_qaNCG__Main {
  background: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.SeoNews-module_seo-news__title_I1SAc__Main {
  color: var(--text-primary);
}

